import React, { useState, useEffect } from 'react';
import './Home.css'; // Обновите путь к вашему CSS-файлу

const Home = () => {
  const [loading, setLoading] = useState(true);
  const [schoolInfo, setSchoolInfo] = useState(null);
  const [reports, setReports] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    const username = sessionStorage.getItem('username');
    const password = sessionStorage.getItem('password');

    if (!username || !password) {
      window.location.href = 'https://флагманы.движение32.рф/login/';
      return;
    }

    const fetchReports = async () => {
      try {
        const response = await fetch('https://движение32.рф/rating/info/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username: username,
            password: password,
          }),
        });

        const data = await response.json();

        if (response.ok) {
          setSchoolInfo(data.school_info);
          setReports(data.reports);
        } else {
          setError('Ошибка загрузки данных');
        }
      } catch (err) {
        setError('Ошибка соединения с сервером');
      } finally {
        setLoading(false);
      }
    };

    fetchReports();
  }, []);

  if (loading) {
    return (
      <div className="loader-container">
        <div className="loader"></div>
      </div>
    );
  }

  if (error) {
    return <p className="error-message">{error}</p>;
  }

  return (
    <div className="home-page-container">
      <div className="school-info">
        <h2>{schoolInfo.name}</h2>
        <p><strong>Муниципалитет:</strong> {schoolInfo.district}</p>
        <p><strong>Баллы:</strong> {schoolInfo.points}</p>
      </div>
      <h2>Отчёты</h2>
      {reports.length > 0 ? (
        <div className="reports-list">
          {reports.map((report, index) => (
            <div key={index} className="report-item">
              <h3>{report.report_type} - {report.report_sub_type}</h3>
              <p><strong>Дата:</strong> {report.event_date}</p>
              <p><strong>Баллы:</strong> {report.points}</p>
              <p><strong>Количество участников:</strong> {report.participants_count}</p>
              <p><a href={report.link} target="_blank" rel="noopener noreferrer">Ссылка на отчёт</a></p>
            </div>
          ))}
        </div>
      ) : (
        <p>Нет доступных отчётов для отображения.</p>
      )}
    </div>
  );
};

export default Home;