import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './CreateReport.css';

const CreateReport = () => {
  const [schoolInfo, setSchoolInfo] = useState(null);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [subcategoryOptions, setSubcategoryOptions] = useState([]);
  const [category, setCategory] = useState(null);
  const [subcategory, setSubcategory] = useState(null);
  const [participants, setParticipants] = useState('');
  const [date, setDate] = useState(null);
  const [link, setLink] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const username = sessionStorage.getItem('username');
        const password = sessionStorage.getItem('password');
  
        const response = await fetch(`https://движение32.рф/rating/api/school-info-and-categories/?username=${username}&password=${password}`);
        if (!response.ok) {
          throw new Error('Ошибка при загрузке данных');
        }
  
        const data = await response.json();
        setSchoolInfo({ ...data.school_info, id: data.school_id });  // Сохраняем ID школы
        setCategoryOptions(data.categories.map(category => ({
          value: category.value,
          label: category.label,
        })));
        setSubcategoryOptions(data.subcategories.map(subcategory => ({
          value: subcategory.value,
          label: subcategory.label,
          reportTypeId: subcategory.report_type_id,
        })));
      } catch (err) {
        setError('Ошибка загрузки данных: ' + err.message);
      }
    };
  
    fetchData();
  }, []);

  const handleParticipantsChange = (e) => {
    const value = e.target.value;
    if (!isNaN(value)) {
      setParticipants(value);
    }
  };

  const handleLinkChange = (e) => {
    setLink(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!category || !subcategory || !participants || !date || !link) {
      const errorMessage = 'Все поля должны быть заполнены';
      setError(errorMessage);
      alert(errorMessage); // Вывод сообщения об ошибке
      return;
    }
  
    if (!link.startsWith('http://') && !link.startsWith('https://')) {
      const errorMessage = 'Введите корректную ссылку с http:// или https://';
      setError(errorMessage);
      alert(errorMessage); // Вывод сообщения об ошибке
      return;
    }
  
    setError('');
    setLoading(true);
  
    const reportData = {
      school_id: schoolInfo.id,  // ID школы из schoolInfo
      category_id: category.value,
      subcategory_id: subcategory.value,
      participants_count: parseInt(participants, 10),
      event_date: date.toISOString().split('T')[0],  // Преобразование даты в формат YYYY-MM-DD
      link: link,
      points: 10  // Вы можете изменить значение или добавить возможность для ввода
    };
    console.log(reportData);
  
    try {
      const response = await fetch('https://движение32.рф/rating/create/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(reportData),
      });
  
      const result = await response.json();
  
      if (response.ok) {
        const successMessage = `Ваш отчёт успешно принят. Вы получили +${result.points} баллов.`;
        alert(successMessage); // Вывод сообщения об успешном создании отчёта

        // Проверяем наличие данных в локальном хранилище
        const username = sessionStorage.getItem('username');
        const password = sessionStorage.getItem('password');
        
        if (username && password) {
          // Если данные есть, переадресовываем на флагманы
          window.location.href = 'https://флагманы.движение32.рф/';
        } else {
          // Если данных нет, переадресовываем на страницу логина
          window.location.href = 'https://флагманы.движение32.рф/login/';
        }
      } else {
        setError(result.error || 'Ошибка при создании отчёта');
        alert(result.error || 'Ошибка при создании отчёта'); // Вывод сообщения об ошибке
      }
    } catch (err) {
      const errorMessage = 'Ошибка отправки данных: ' + err.message;
      setError(errorMessage);
      alert(errorMessage); // Вывод сообщения об ошибке
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="create-report-container">
      <h2>Создать отчёт</h2>

      {error && <p className="error-message">{error}</p>} {/* Показ ошибки */}

      {!schoolInfo ? (
        <p>Загрузка данных...</p>
      ) : (
        <>
          <div className="school-info">
            <h3>Информация о школе</h3>
            <p><strong>Наименование школы:</strong> {schoolInfo.name}</p>
            <p><strong>Муниципалитет:</strong> {schoolInfo.district}</p>
            <p><strong>Количество учеников:</strong> {schoolInfo.students_count}</p>
          </div>

          <form onSubmit={handleSubmit}>
            <h3>Данные отчёта</h3>

            <div className="form-group">
              <label>Категория</label>
              <Select
                value={category}
                onChange={setCategory}
                options={categoryOptions}
                isSearchable
                placeholder="Выберите категорию"
              />
            </div>

            <div className="form-group">
              <label>Подкатегория</label>
              <Select
                value={subcategory}
                onChange={setSubcategory}
                options={subcategoryOptions.filter(opt => opt.reportTypeId === (category && category.value))}
                isSearchable
                placeholder="Выберите подкатегорию"
              />
            </div>

            <div className="form-group">
              <label>Количество участников</label>
              <input
                type="text"
                value={participants}
                onChange={handleParticipantsChange}
                placeholder="Введите количество участников"
              />
            </div>

            <div className="form-group">
              <label>Дата мероприятия</label>
              <DatePicker
                selected={date}
                onChange={(date) => setDate(date)}
                dateFormat="dd/MM/yyyy"
                placeholderText="Выберите дату"
              />
            </div>

            <div className="form-group">
              <label>Ссылка</label>
              <input
                type="text"
                value={link}
                onChange={handleLinkChange}
                placeholder="Введите ссылку"
              />
            </div>

            <button type="submit" disabled={loading}>Создать отчёт</button>
          </form>

          {loading && <div className="loader">Загрузка...</div>}
        </>
      )}
    </div>
  );
};

export default CreateReport;