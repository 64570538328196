import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Home from './Home'; // Компонент "Главная"
import CreateReport from './CreateReport'; // Компонент "Создать отчёт"
import './App.css'; // Стили для меню и общего оформления
import LoginPage from './LoginPage';
import Logo from './logo.png'

function RewardsInfo() {
  return (
    <div className="iframe-container">
      <iframe
        src="https://движение32.рф/bot/pok1/"
        title="За что можно получить баллы?"
      ></iframe>
    </div>
  );
}

function App() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <Router>
      <div className={`App ${isMenuOpen ? 'menu-open' : ''}`}>
        <header className="App-header">
          <button className="menu-button" onClick={toggleMenu}>
            ☰
          </button>
          <img src={Logo} id='logo' alt='1'/>
        </header>

        <aside className={`sidebar ${isMenuOpen ? 'open' : ''}`}>
          <nav>
            <ul>
              <li>
                <Link to="/" onClick={closeMenu}>Главная</Link>
              </li>
              <li>
                <Link to="/create-report" onClick={closeMenu}>Создать отчёт</Link>
              </li>
              <li>
                <Link to="/rewards-info" onClick={closeMenu}>За что можно получить баллы?</Link>
              </li>
            </ul>
          </nav>
        </aside>

        <main className="content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/create-report" element={<CreateReport />} />
            <Route path="/rewards-info" element={<RewardsInfo />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;